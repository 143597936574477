.content {
  margin: -100px 0 0;
  padding: 0;
  min-width: 280px;
  max-width: 800px;
  color: #fff;

  .iconThankYou {
    display: inline-block;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 20px;
  }

  .description {
    text-align: center;
    max-width: 360px;
    font-size: 18px;
    line-height: 24px;
    margin: 0 auto;
  }

  .joinBox {
    margin-top: 40px;

    .info {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .btnGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .btnGooglePlay {
    display: inline-block;
    width: 149px;
    height: 44px;
    margin-bottom: 10px;
    background: url("../../../../public/images/btn-google-play.png") top left
      no-repeat;
  }

  .btnAppStore {
    display: inline-block;
    width: 132px;
    height: 44px;
    background: url("../../../../public/images/btn-app-store.png") top left
      no-repeat;
  }
}
