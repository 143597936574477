.content {
  margin: -50px 0 0;
  padding: 0;
  width: 280px;
  color: #383838;
  width: 100%;

  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 10px;
  }

  .titleReady {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 10px;
  }

  .textReady {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    margin: 0 0 10px;
  }

  .formFieldset {
    border: none;
  }

  label {
    font-size: 14px;
    text-align: left;
    color: #1f2d51;
    display: inline-block;
    margin-bottom: 10px;
  }

  .formLoginInput {
    margin-bottom: 20px;
    padding: 0 30px 0 0;

    input {
      width: 100%;
      padding: 15px;
      font-size: 16px;
      border: none;
      outline: none;
      border-radius: 50px;
    }
  }

  .formInput {
    position: relative;
  }

  .iconViewPass {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 39px;
    right: 14px;
    cursor: pointer;

    img {
      width: 24px;
    }
  }

  .submitBtn {
    width: 100%;
    padding: 13px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    color: #fff;
    background-color: #4263eb;
    border-radius: 50px;
    cursor: pointer;

    &:hover {
      background-color: #768ff5;
    }
  }
  .errorMessage {
    padding: 10px 15px;
    font-size: 14px;
    text-align: center;
    border-radius: 12px;
    background-color: #b50519;
    color: #fff;
    margin-bottom: 15px;
  }
}
