.content {
  margin: -100px 0 0;
  padding: 0;
  min-width: 280px;
  max-width: 800px;
  text-align: center;
  color: #383838;

  .iconThankYou {
    display: inline-block;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 42px;
    font-weight: 900;
    margin: 0 0 20px;
  }

  p {
    font-size: 18px;
    margin: 0;
  }
}
