.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    padding: 10px 20px;
    border-bottom: 1px solid #197353;
    text-align: center;
    min-width: 280px;
  }

  .logo {
    width: 65px;
    height: 65px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .main {
    flex: 1;
    max-width: 980px;
    padding: 20px;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer {
    min-width: 280px;
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);

    .copyright {
      font-size: 14px;
    }
  }
}
